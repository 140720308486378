/* latin - loaded this way to work with html-to-image package */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw5aXp-p7K4KLg.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Hw5aXp-p7K4KLg.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu173w5aXp-p7K4KLg.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.root {
  --project-navigation-height: 150;
}

html {
  --left-menu-margin: 5.4rem;
  --lower-right-menu-height: 0rem;
  font-size: 10px;
}
@media (width <= 1400px) or (height <= 800px) {
  html {
    font-size: 9px;
  }
}
@media (width <= 1300px) or (height <= 800px) {
  html {
    font-size: 8px;
  }
}
@media (width <= 1200px) or (height <= 700px) {
  html {
    font-size: 7px;
  }
}
@media (width <= 1000px) or (height <= 600px) {
  html {
    font-size: 6px;
  }
}

body {
  margin: 0;
  font-family: "Montserrat", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: "Montserrat", "Ubuntu", "Courier New", monospace;
}

button {
  font-family: "Montserrat", "Ubuntu", "Courier New", monospace;
  outline: none;
  border: none;
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash,
.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line,
.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_point {
  display: none;
}

.stats {
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 200px;
  background: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 1.3rem;
  line-height: 1;
  color: #6b6b76;
  outline: none;
  border-radius: 6px;
}

.cross-out-button {
  float: right;
  position: relative;
  top: -40px;
  right: -10px;
  z-index: 1002;
}

.cross-out-button:after {
  content: "\00d7";
}

input[type="range"] {
  display: block;
  margin: 0;
  width: 100%;
}

.ticks {
  display: flex;
  font-size: 8px;
  justify-content: space-between;
}

.tick {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1px;
  background: gray;

  height: 3px;
  line-height: 15px;
  margin-bottom: 2px;
}

.toast-message {
  background-color: black;
  color: white;
  text-align: center;
  padding: 1.2rem 1.8rem;
  z-index: 10;
  border-radius: 0.4rem;
  transition: opacity 0.25s;
}

.mapboxgl-popup-content {
  background-color: #f5f5f5 !important;
  border-radius: 6px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3) !important;
  color: #264e5d;
  padding: 0 !important;
}

.mapboxgl-popup-tip {
  display: none !important;
}

.mapboxgl-popup-close-button {
  display: none !important;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.3rem;
}

#datGUI {
  top: auto;
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  z-index: 3;
  height: fit-content;
}

input[type="checkbox"] {
  height: 1.3rem;
  width: 1.3rem;
}

.mapboxgl-popup,
.mapboxgl-popup-anchor-bottom {
  max-width: fit-content !important;
}
